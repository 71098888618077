$sky-blue: #59deff;
$ice-white: #ffffff;
$ice-blue: #e6f0f8;
$ice-dark-blue: #76a1c1;
$desert-orange: #f8b25e;
$forest-yellow: #eee38b;
$forest-green: #4a940d;
$cactus-spot-green: #809000;
$forest-tree-green: #4a9411;
$forest-yellow-light-lines: #e4d985;
$forest-yellow-dark-lines: #a67f4a;
