@import '../../styles/colours';
@import '../../styles/mixins';
@import '../../styles/spaces';

.Ice {
    @include medium-screens {
        padding: $large-space $medium-space;
    }
    @include small-screens {
        padding: $large-space 0 0 0;
        overflow: hidden;
    }
    background-color: $ice-white;
    margin-top: -1px;
    padding: $large-space 5vh $medium-space 5vh;
    position: relative;
    text-align: center;
    width: 100%;

    &::before {
        content: '';
        height: 59px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        box-shadow: 0px -20px 20px 0px $desert-orange;
        background-image:
        linear-gradient(135deg, $desert-orange 30px, rgba(255,255,255,0) 30px),
        linear-gradient(-135deg, $desert-orange 30px, rgba(255,255,255,0) 30px);
        background-position: 0 0, 0 0;
        background-size: 60px 60px, 60px 60px;
        background-repeat: repeat-x;
    }
}

.Clearing {
    @include large-screens {
        max-width: 1200px;
        justify-content: center;
    }
    @media screen and (min-width: 761px) and (max-width: 1028px) {
        background: url('../../images/mountains-scene-medium.png') center center no-repeat;
        background-size: contain;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin: $medium-space 0;

        &.Upcoming {
            background: url('../../images/mountains-scene-large-small.png') center center no-repeat;
            background-size: contain;
        }
    }
    @media only screen and (min-width: 431px) and (max-width: 760px) {
        background: url('../../images/mountains-scene-large-small.png') center center no-repeat;
        background-size: contain;
        margin: $medium-space 0;
        padding: 0 $base;
    }
    @media only screen and (max-width: 430px) {
        background: url('../../images/mountains-scene-small.png') top center no-repeat;
        background-size: 90% 90%;
        margin: $small-space 0;
        padding: $small-space/1.25;
    }
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    min-height: 300px;
    position: relative;
    width: 100%;
}

.SteamWidgetWrapper {
    @media only screen and (min-width: 1029px) {
        background: url('../../images/mountains-scene.png') center center no-repeat;
        background-size: contain;
        width: 70%;
        max-width: 800px;

        &::after {
            content: '';
            display: block;
            padding-top: 55.53%;
            width: 0px;
            margin-bottom: $xlarge-space;
        }
    }
    @media screen and (min-width: 761px) and (max-width: 1028px) {
        min-width: 550px;
        margin: 0 $large-space $large-space $large-space;
    }
    @include small-screens {
        padding: $large-space $small-space 0 $small-space;
        width: 100%;

        &::after {
            content: '';
            display: block;
            padding-top: 55.53%;
            width: 0px;
        }
    }
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.SteamWidget {
    @media only screen and (min-width: 1029px) {
        max-width: 80%;
        min-width: 550px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -40%);
    }
    @media only screen and (max-width: 1028px) {
        min-width: 550px;
    }
}

.Content {
    @include large-screens {
        margin-right: $large-space;
    }
    @media screen and (min-width: 761px) and (max-width: 1028px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 $medium-space;
        padding: $large-space*1.5 0 $medium-space 0;
    }
    @media only screen and (min-width: 431px) and (max-width: 760px) {
        width: 342px;
        padding-top: $small-space;
        margin: 0 auto;
        overflow: hidden;
    }
    @media only screen and (max-width: 430px) {
        margin: 0 auto;
        padding: $small-space 0;
        width: 90%;
        overflow: hidden;
    }

    &.Upcoming {
        @include large-screens {
            padding: $large-space 0;
        }
    }

    &:not(.Upcoming) {
        h2 {
            margin-bottom: $small-space;
        }
        @media only screen and (min-width: 431px) and (max-width: 760px) {
            .SteamLink a {
                max-width: 300px;

                br {
                    display: none;
                }
            }
        }
        @media only screen and (max-width: 430px) {
            .SteamLink a {
                width: 200px;
            }
        }
    }

    h2 {
        @include large-screens {
            max-width: 300px;
        }
        @include medium-screens {

            br {
                display: none;
            }
        }
        @include small-screens {
            max-width: 80%;
            font-size: 1.4rem;
            margin: $small-space auto;
        }
        font-weight: 800;
        margin: 0;
    }
}

.BloomingCactus {
    @media only screen and (min-width: 1029px) {
        position: relative;
        left: auto;
        bottom: auto;
    }
    @media only screen and (min-width: 609px) and (max-width: 761px) {
        height: 120px;
        left: -10px;
    }
    @media only screen and (max-width: 608px) {
        height: 80px;
        left: -10px;
    }
    @include small-screens {
        position: relative;
        left: 0;
        bottom: 0;
        margin-bottom: $medium-space;
    }
    bottom: -40px;
    height: 160px;
    left: 20px;
    position: absolute;
    z-index: 3;
}

.BloomingCactusTwo {
    @media only screen and (min-width: 609px) and (max-width: 761px) {
        height: 90px;
        left: 40px;
        bottom: -25px;
    }
    @media only screen and (max-width: 608px) {
        height: 50px;
        left: 30px;
        bottom: -15px;
    }
    bottom: -50px;
    display: block;
    height: 120px;
    left: 100px;
    position: absolute;
    z-index: 4;
}

.DayCounterWrapper {
    @include small-screens {
        font-size: 2rem;
        padding: 0 0 $small-space 0;
    }
    align-items: center;
    color: $ice-dark-blue;
    display: flex;
    flex-direction: column;
    font-size: 40px;
    font-weight: 800;


    p {
        margin: 0;
    }
}

.DayCounter {
    @media only screen and (max-width: 1028px) {
        background-color: $ice-blue;

        &::before {
            content: '';
            position: absolute;
            top: #{(80/2) - 3}px;
            background-color: $ice-dark-blue;
            height: 6px;
            width: 200px;
            left: -200px;
            z-index: 3;
        }

        &::after {
            content: '';
            position: absolute;
            top: #{(80/2) - 15}px;
            background-image: radial-gradient($ice-dark-blue 20%, transparent 20%);
            background-size: 25px 25px;
            background-repeat: repeat-x;
            height: 30px;
            width: 200px;
            left: 80px;
            z-index: 1;
        }
    }
    @media screen and (min-width: 761px) and (max-width: 1028px) {
        margin: $small-space*1.5;
    }
    @media only screen and (max-width: 760px) {
        height: 60px;
        width: 60px;
        font-size: 1.5rem;
        margin: $base;

        &::after {
            left: 60px;
            top: #{(60/2) - 15}px;
        }

        &::before {
            top: #{(60/2) - 6}px;
        }
    }
    align-items: center;
    border-radius: 50%;
    border: 6px solid $ice-dark-blue;
    display: flex;
    height: 80px;
    justify-content: center;
    position: relative;
    text-align: center;
    margin: $medium-space;
    width: 80px;
    z-index: 5;
}

.Mobile {
    @include large-screens {
        display: none;
    }
    @include medium-screens {
        display: none;
    }
    position: relative;
}

.Tablet {
    @media only screen and (min-width: 1029px) {
        display: none;
    }
    @include small-screens {
        display: none;
    }
}

.NotMobile {
    @include small-screens {
        display: none;
    }
}


.SteamLink {
    a {
        transition: all 200ms;
        margin: 0 auto $medium-space auto;
        display: inline-block;
        background-color: $ice-white;
        text-decoration: none;
        font-size: 0.95rem;
        cursor: pointer;
        color: black;
        border: 0;
        font-weight: 800;
        line-height: 1.25;
        margin: 0 $small-space $medium-space $small-space;
        padding: $small-space/4 $small-space/2;
        outline: 3px solid black;

        &:hover {
            background-color: $desert-orange;
        }
    }
}
