@import '../../styles/colours';
@import '../../styles/mixins';
@import '../../styles/spaces';

.Newsletter {
    @include large-screens {
        padding: $xlarge-space 0 $large-space;
    }
    @include medium-screens {
        padding: $large-space 0 $large-space;
    }
    @include small-screens {
        padding: $large-space 0 $medium-space;
    }
    min-height: 26px;
    background-color: $forest-yellow;
    position: relative;
    overflow: hidden;
    text-align: center;

    h2 {
        @include small-screens {
            margin: 0 auto;
            width: 80%
        }
        line-height: 1.4;
        width: 70%;
        max-width: 640px;
        text-align: center;
        margin: $small-space auto 0;
    }

    p {
        font-weight: 500;
        margin: $small-space/2 auto $small-space auto;
        max-width: 80%;
    }

    &::before {
        content: '';
        height: 60px;
        left: 0;
        position: absolute;
        top: -2px;
        width: 100%;
        background-image:
        linear-gradient(135deg, $ice-white 30px, rgba(255,255,255,0) 30px),
        linear-gradient(-135deg, $ice-white 30px, rgba(255,255,255,0) 30px);
        background-position: 0 0, 0 0;
        background-size: 60px 60px, 60px 60px;
        background-repeat: repeat-x;
    }

}

.Cactus {
    align-items: center;
    display: flex;
    position: relative;
    justify-content: center;


    &::before {
        content: '';
        @include small-screens {
            background-size: auto 160px;
            height: 160px;
            width: 102px;
        }
        transition: transform 1s ease-in-out;
        background-image: url('../../images/head-segment.png');
        background-size: auto 201px;
        background-position: 0px 0px;
        background-repeat: no-repeat;
        transform: translateX(100vw);
        position: absolute;
        top: 0;
        left: 0;
        height: 201px;
        width: 125px;
    }

    &::after {
        content: '';
        @include small-screens {
            background-size: auto 160px;
            height: 160px;
            left: 100px;
            width: calc(100% - 102px);
        }
        transition: transform 1s ease-in-out;
        background-image: url('../../images/body-segment.png');
        background-size: auto 201px;
        background-position: 0 0px;
        position: absolute;
        top: 0;
        left: 125px;
        height: 200px;
        width: calc(100% - 125px);
        transform: translateX(100vw);
    }

    &.Visible {
        &::before,
        &::after {
            transform: translateX(0);
        }
    }

    form {
        @include large-screens {
            padding-top: $medium-space;
        }
        @include medium-screens {
            padding-top: $medium-space;
        }
        @include small-screens {
            padding-top: $small-space;
            height: 160px;
        }
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        width: 100%;
        z-index: 3;
    }

    input,
    label,
    button {
        @include small-screens {
            font-size: 1rem;
        }
        font-size: 1.3rem;
    }

    input {
        @include medium-screens {
            max-width: 300px;
        }
        @include small-screens {
            max-width: 170px;
        }
        height: 34px;
        border: 0;
        padding: $small-space/4 $small-space/2;
        outline: 3px solid black;
        margin-right: 0;
    }
}

.Submit {
    cursor: pointer;
    transition: all 200ms;
    background-color: $ice-white;
    color: black;
    border: 0;
    height: 34px;
    font-weight: 800;
    line-height: 15px;
    padding: $small-space/4 $small-space/2;
    outline: 3px solid black;

    &:hover {
        background-color: $desert-orange;
    }
}

.VisuallyHidden {
    position: absolute;
    top: -1000px;
    left: -1000px;
}
