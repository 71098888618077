@import './colours';
@import './spaces';
@import './mixins';

h2 {
    @include medium-screens {
        font-size: 1.8rem;
    }
    @include small-screens {
        font-size: 1.6rem;
    }
    font-size: 2rem;
    font-weight: 800;
    margin: 0;
}

h3 {
    @include medium-screens {
        font-size: 1.4rem;
    }
    @include small-screens {
        font-size: 1.2rem;
    }
    font-size: 1.5rem;
    font-weight: 800;
    margin: 0;
}

p {
    @include medium-screens {
        font-size: 1.2rem;
    }
    @include small-screens {
        font-size: 1rem;
    }
    font-size: 1.4rem;
    line-height: 1.3;

    a {
        font-weight: 500;
        color: black;
        text-decoration: none;
        position: relative;

        &::after {
            content: '';
            border-bottom: 2px solid rgba(black, 0.3);
            bottom: 0;
            left: 0;
            position: absolute;
            transition: border-bottom-color 200ms;
            width: 100%;
        }

        &:hover::after {
            border-bottom-color: black;
        }
    }
}
