
@import '../../styles/mixins';
@import '../../styles/spaces';
@import '../../styles/colours';

.Links {
    @include small-screens {
        font-size: 1.25rem;
        top: $base;
        right: $base;
    }
    font-size: 1.5rem;
    position: absolute;
    top: $medium-space;
    right: $medium-space;
    z-index: 2;

    a {
        transition: all 200ms;
        color: mix(black, $sky-blue, 70%);
        padding: $base/2;

        &:hover {
            color: black;
        }
    }
}

.Header {
    @include large-screens {
        background: url('../../images/banner-large.png') top center / auto 100% no-repeat;
    }
    @include medium-screens {
        background: url('../../images/banner.png') top center / cover no-repeat;
    }
    @include small-screens {
        background: url('../../images/banner-small.png') top center / cover no-repeat;

    }
    padding: 4vh 0 6vh 0;
    position: relative;
    min-height: 96vh;
    width: 100%;

    h2 {
        @include small-screens {
            margin: 0 auto $large-space;
            width: 80%;
        }
        line-height: 1.4;
        width: 70%;
        max-width: 640px;
        text-align: center;
        margin: 0 auto $large-space;
    }
}

.LogoWrapper {
    @include small-screens {
        margin: 0 auto $small-space/0.75 auto;
        height: auto;
        width: 70vw;
    }
    margin: 0 auto $medium-space auto;
    position: relative;
    width: 55vh;
    z-index: 1;

    &::before {
        content: '';
        display: block;
        padding-top: 57.26%;
        position: relative;
    }
}

.Logo {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}


.VideoWrapper {
    @include small-screens {
        height: auto;
        width: 80vw;
    }
    margin: 0 auto;
    position: relative;
    width: 70vh;

    &::before {
        content: '';
        display: block;
        padding-top: 56.25%;
        position: relative;
    }
}

.Video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    outline: 3px solid black;
    background-color: black;
}
