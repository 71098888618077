@import '../../styles/colours';
@import '../../styles/mixins';
@import '../../styles/spaces';

.Forest {
    @include large-screens {
        background-color: $forest-yellow;
        padding: $xlarge-space 0 $large-space 0;

    }
    @include medium-screens {
        padding: $large-space 0 $large-space 0;
    }
    @include small-screens {
        background-color: $forest-green;
    }
    background-image:
        linear-gradient(to right, $forest-yellow-light-lines 2px, transparent 2px),
        linear-gradient(to bottom, $forest-yellow-light-lines 2px, transparent 2px);
        background-size: 80px 80px, 80px 80px;
        background-color: $forest-yellow;
    min-height: 200px;
    position: relative;
    overflow: hidden;
    text-align: center;
}

.ForestWrapper {
    @include large-screens {
        background: url('../../images/forest-wall.png') $large-space 0 no-repeat;
        background-size: contain;
        padding: 0 $large-space;
        flex-direction: row;
        justify-content: space-between;
    }
    @media only screen and (min-width: 886px) and (max-width: 1199px) {
        justify-content: center;
        background-position: bottom right;
        background-size: 100% auto;
        flex-direction: row;
        min-width: 320px;
        min-height: 320px;
        padding: 0 8%;
    }
    @media only screen and (min-width: 761px) and (max-width: 885px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0 4%;
    }
    @include small-screens {
        flex-direction: column-reverse;
    }
    display: flex;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.MoreAboutWrapper {
    @include large-screens {
        width: 540px;
        padding: 60px 80px 110px 80px;
    }
    @include medium-screens {
        background: url('../../images/forest-wall.png') bottom left no-repeat;
        background-size: contain;

        &::before {
            content: '';
            padding-top: 100%;
            position: relative;
            display: block;
            width: 440px;
        }
    }
    @include small-screens {
        background: url('../../images/forest-scene-small-long.png') top $large-space center no-repeat $forest-green;
        background-size: 320px auto;
        margin: 0 auto;
        width: 100%;

        &::before {
            content: '';
            height: 59px;
            left: 0;
            position: absolute;
            top: -1px;
            width: 100%;
            background-image:
            linear-gradient(135deg, $forest-yellow 30px, rgba(255,255,255,0) 30px),
            linear-gradient(-135deg, $forest-yellow 30px, rgba(255,255,255,0) 30px);
            background-position: 0 0, 0 0;
            background-size: 60px 60px, 60px 60px;
            background-repeat: repeat-x;
            z-index: 2;
        }
    }
    position: relative;
}

.MoreAbout {
    @include large-screens {
        padding: 50px 80px 50px 50px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    @include medium-screens {
        padding: 50px 50px 50px 50px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    @include small-screens {
        height: 390px;
    }
}

.MoreAboutMobile {
    @include small-screens {
        padding: 36px 30px 0 30px;
        max-width: 340px;
        margin: $large-space*1.25 auto 0 auto;
    }
}

.Support {
    font-weight: 500;
}

.Press {
    @include medium-screens {
        width: 100%;
        padding: $large-space 4% 0 4%;
        margin-bottom: 0;

        h2 {
            margin-bottom: $base;
            margin-right: $small-space;
        }

        h2, p {
            display: inline-block;
            text-align: left;
            margin-top: 0;
        }
    }
    @include small-screens {
        margin: 0 $medium-space;
    }
    margin-bottom: $large-space*1.5;

    &.Tablet {
        @include large-screens {
            display: none;
        }
        @include small-screens {
            display: none;
        }
    }

    &.NonTablet {
        @include medium-screens {
            display: none;
        }
        @include small-screens {
            display: none;
        }
    }
}

.Sign {
    @include large-screens {
        background: url('../../images/signpost-forest.png') top left no-repeat;
        background-size: auto 330px;
        background-position: bottom 0px center;
        flex-direction: column;
        width: 45%;
        min-width: 330px;
    }
    @media only screen and (min-width: 886px) and (max-width: 1199px) {
        background: url('../../images/signpost-forest.png') top left no-repeat;
        align-self: flex-end;
        align-items: center;
        background-position: bottom -4px right;
        background-size: 100% auto;
        flex-direction: column;
        min-width: 320px;
        min-height: 320px;
        margin-left: $large-space;
    }
    @media only screen and (min-width: 761px) and (max-width: 885px) {
        background: url('../../images/signpost-forest.png') top left no-repeat;
        position: absolute;
        bottom: 0;
        right: 4%;
        align-self: flex-end;
        align-items: center;
        background-position: bottom -4px right;
        background-size: 100% auto;
        flex-direction: column;
        min-width: 320px;
        min-height: 320px;
    }
    @include small-screens {
        background-image:
        linear-gradient(to right, $forest-yellow-light-lines 2px, transparent 2px),
        linear-gradient(to bottom, $forest-yellow-light-lines 2px, transparent 2px);
        background-size: 80px 80px, 80px 80px;
        background-color: $forest-yellow;
        padding: $medium-space 4% 0 4%;
        flex-direction: column;

        h2 {
            margin-bottom: $medium-space;
        }
    }
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.SignText {
    @include large-screens {
        height: 270px;
        max-width: 330px;
        padding: 0 20px 100px 50px;
    }
    @include medium-screens {
        height: 320px;
        padding: 80px 20px 100px 60px;
    }
    @include small-screens {
        background: url('../../images/signpost-forest.png') top left no-repeat;
        background-size: contain;
        background-position: top left;
        height: 250px;
        max-width: 240px;
        padding: 35px 30px 0 40px;
    }
    position: relative;
    margin: 0 auto;
    width: 100%;

    p {
        white-space: nowrap;
        margin-top: 0;
        text-align: left;

        svg {
            color: white;
            vertical-align: middle;
            margin-right: $base;

            &.Itchio {
                vertical-align: -2px;
            }

            &.LinkedIn {
                vertical-align: -2px;
            }
        }

        a {
            color: white;

            &:hover::after {
                border-bottom-color: rgba(255, 255, 255, 0.8);
            }
        }
    }
}

.Logo {
    width: 300px;
}

.LogoAck {
    margin-top: $medium-space;
    margin-bottom: 0;
}

.NotDesktop {
    @include large-screens {
        display: none;
    }
}
