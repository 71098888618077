@import './styles/colours';
@import './styles/mixins';

.App {
    background-color: $sky-blue;
}

.Footer {
    @include large-screens {
        border-top: 4px solid black;
    }
    @include medium-screens {
        border-top: 4px solid black;
    }
    background-color: $forest-tree-green;
    border-bottom: 4px solid black;
    padding: 10px 32px;
    color: $ice-white;
    text-align: center;

    p {
        font-size: 0.8rem;
        margin: 0;

        a {
            color: $ice-white;
        }
    }
}
