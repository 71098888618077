/* m-plus-rounded-1c-regular - latin */
@font-face {
    font-family: 'M PLUS Rounded 1c';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('../fonts/m-plus-rounded-1c-v10-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/m-plus-rounded-1c-v10-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* m-plus-rounded-1c-500 - latin */
  @font-face {
    font-family: 'M PLUS Rounded 1c';
    font-style: normal;
    font-weight: 500;
    src: local(''),
         url('../fonts/m-plus-rounded-1c-v10-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/m-plus-rounded-1c-v10-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* m-plus-rounded-1c-800 - latin */
  @font-face {
    font-family: 'M PLUS Rounded 1c';
    font-style: normal;
    font-weight: 800;
    src: local(''),
         url('../fonts/m-plus-rounded-1c-v10-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../fonts/m-plus-rounded-1c-v10-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }